import React from "react";
import { useEffect, useState } from "react";
import Box from "@mui/material/Box";

import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import AgricultureIcon from "@mui/icons-material/Agriculture";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Button from "@mui/material/Button";


import Engineering from "@mui/icons-material/Engineering";
import { Link } from "react-router-dom"
import './index.css';


var w = window.innerWidth;

const Body = () => {
  const handleClickScroll = () => {
    const element = document.getElementById("section");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
 

  return(
    <>
    <div className="body mainSection">
      <div className="body carouselContainer" style={{  }}>
        <Carousel
          autoPlay={true}
          interval={3000}
          //dynamicHeight={"100vh"}
          showThumbs={false}
          showStatus={false}
          infiniteLoop={true}
          autoFocus={true}
          style={{
            display: "block",
            paddingTop: "10vh",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <div>
            <img src={require("./assets/image7.jpg")} />
          </div>

          <div>
            <img src={require("./assets/image3.jpg")} />
          </div>
          <div>
            <img src={require("./assets/image4.jpg")} />
          </div>
          <div>
            <img src={require("./assets/image_scope.jpg")} />
          </div>
        </Carousel>
        <div className="sideMaintainer" style={{ fontWeight: "bold" }}>
          A company providing solutions on multiple fronts. <br />
              <Button
              className="smallKilled"
                variant="contained"
                sx={{
                  height: "8vh",
                  width: "40vh",
                  fontFamily: "Poppins",
                  background: "##A7B6BB",
                  color: "white",
                  borderRadius: "15px",
                }}
                onClick={handleClickScroll}
              >
                See how we can help
                <span style={{ marginLeft:"1rem", display: "flex", justifyContent: "center" }}>
                  <ArrowDownwardIcon />
                </span>
              </Button>
        </div>
      </div>
    </div>
    <div className="section" id="section">
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={1}>
              <Grid
                item
                xs={12}
                md={6}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <img
                  className="imgshadow"
                  src={require("./assets/image_nurseworking.jpg")}
                  style={{height: "25vmax", display: "block", marginLeft: "auto", marginRight: "auto"}}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: w < 420 && "center",
                  marginTop: w < 420 && "2vh",
                }}
              >
                <span style={{fontWeight: "bold", fontSize: w > 420 ? "1.5rem" : "1rem"}}>
                  Overseas Recruitment Of Healthcare Professionals
                </span>
                <br />
                The need for Indian Nurses in the western countries has
                increased tremendously post COVID. India, because of its vast
                population and availability of skilled professionals, has been
                able to meet this requirement of the developed countries.
              </Grid>
            </Grid>
          </Box>
      </div>


      <div className="section" style={{  }} >
        <Typography sx={{fontSize: "4vmax", textAlign: "center", paddingBottom: w > 420 ? "10vh" : "5vh",}}>
          <div className="under">Services</div>
        </Typography>

        <Grid container alignItems="center" justifyContent="center">
          <Grid
            className="imgshadow serviceBox"
            item
            xs={12}
            md={2.5}
            sx={{  }}
          >
            <Grid item xs={12} md={12}>
              <VpnKeyIcon
                sx={{ color: "#537FE7", fontSize: "4vmax", paddingTop: "3vh" }}
              />
            </Grid>
            <div
              style={{
                fontWeight: "600",
                textAlign: "center",
                paddingBottom: "2vh",
              }}
            >
              <Link
                style={{ textDecoration: "none", color: "black" }}
                to={`/facilitation`}
              >
                Nursing
              </Link>
            </div>
            We act like a bridge between the aspiring candidates and the
            vacancies available at the hospitals. With the help of our German
            counterparts, we facilitate the process of Language Learning and
            Employment.
          </Grid>
          <Grid
            item
            className="imgshadow serviceBox"
            xs={12}
            md={2.5}
            sx={{  }}
          >
            <Grid item xs={12} md={12}>
              <Engineering
                sx={{ color: "#537FE7", fontSize: "4vmax", paddingTop: "3vh" }}
              />
            </Grid>
            <div
              style={{
                fontWeight: "600",
                textAlign: "center",
                marginBottom: "2vh",
              }}
            >
              <Link
                style={{ textDecoration: "none", color: "black" }}
                to={`/study`}
              >
                {" "}
                Study{" "}
              </Link>
            </div>
            Europe, renowned as a multicultural hub and a blend of diverse cultures and nationalities, has consistently ranked among the top study destinations worldwide. 
            Europe is also vastly different from East to West, consisting of 50 countries and 5 major time zones. 
          </Grid>
          <Grid
            item
            className="imgshadow serviceBox"
            xs={12}
            md={2.5}
            sx={{  }}
          >
            <Grid item xs={12} md={12}>
              <CastForEducationIcon
                sx={{ color: "#537FE7", fontSize: "4vmax", paddingTop: "3vh" }}
              />
            </Grid>
            <div
              style={{
                fontWeight: "600",
                textAlign: "center",
                marginBottom: "2vh",
              }}
            >
              <Link
                style={{ textDecoration: "none", color: "black" }}
                to={`/training`}
              >
                Training
              </Link>
            </div>
            In order to get your career off to a good start in another country,
            it is important to learn local language, for example German. As a
            trusted policy holder, SWAPAL can help you with this.
          </Grid>
          <Grid
            item
            className="imgshadow serviceBox"
            xs={12}
            md={2.5}
            sx={{  }}
          >
            <Grid item xs={12} md={12}>
              <AgricultureIcon
                sx={{ color: "#537FE7", fontSize: "4vmax", paddingTop: "3vh" }}
              />
            </Grid>
            <div
              style={{
                fontWeight: "600",
                textAlign: "center",
                marginBottom: "2vh",
              }}
            >
              <Link
                style={{ textDecoration: "none", color: "black" }}
                to={`/study`}
              >
                Work Permits
              </Link>
            </div>
            Do you already have detailed career plans? Do you want to work, gain international experience, and receive a European salary?
            We assist in recruiting skilled and unskilled workers in various sectors. 
            Whether you are just starting your career, or you simply want to change your place of work in search of better perspectives abroad, we can offer you interesting development opportunities.
            
          </Grid>
        </Grid>
      </div>


                </>

                
  )
};

export default Body;
