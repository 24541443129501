import React from 'react'

import Body from './Body'
import Body2 from './Body2'

const HomePage = () => {
  return (
    <Body2/>
  )
}

export default HomePage